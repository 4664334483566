import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/games/Index'),
    meta: { title: '趣单' }
  },
  {
    path: '/games/multiplication',
    name: 'Multiplication',
    component: () => import('@/views/games/Multiplication'),
    meta: { title: '趣味乘法练习' }
  },
  {
    path: '/passport/signin',
    name: 'Signin',
    component: () => import('@/views/passport/Signin'),
    meta: { title: '登录' }
  },  
  {
    path: '/passport/signup',
    name: 'Signup',
    component: () => import('@/views/passport/Signup'),
    meta: { title: '注册' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
