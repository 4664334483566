import { showToast } from 'vant'
const pattern = /^1[3456789]\d{9}$/
const utils = {
    errorMessage(message) {
        showToast({
            type: 'html',
            position: 'bottom',
            message: '<i class="iconfont icon-error" style="color: #ffff00;"></i>&nbsp;&nbsp;' + message,
            forbidClick: true,
        })
    },
    successMessage(message) {
        showToast({
            type: 'html',
            position: 'bottom',
            message: '<i class="iconfont icon-success" style="color: #00ff00;"></i>&nbsp;&nbsp;' + message,
            forbidClick: true,
        })
    },
    isMobile(mobile) {
        return pattern.test(mobile);
    }

}

export default utils