import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

// 根据路由设置标题
router.beforeEach((to, from, next) => {
    /*路由发生改变修改页面的title */
    if (to.meta["title"]) {
        document.title = to.meta["title"]
    }
    next();
})

import Vant from 'vant';
import 'vant/lib/index.css'
import '@/assets/iconfont/iconfont.css'
import '@/assets/css/common.less'

import utils from '@/assets/js/utils'

// 自动注册组件
const requireComponents = require.context('./components', true, /\.vue/)
requireComponents.keys().forEach(fileName => {
    const componentInstance = requireComponents(fileName)
    app.component(componentInstance.default.name, componentInstance.default || componentInstance)
})

app.config.globalProperties.$utils = utils

app.use(store)
app.use(router)
app.use(Vant)
app.mount('#app')
